import React from 'react'
import FanmeDialog from '@/components/molecules/Alert/index'

interface Props {
  message: string | React.ReactNode
  close: (v: InfoState) => void
  visible: boolean
}

type InfoState = {
  message: string | React.ReactNode
  close: (v: InfoState) => void
  visible: boolean
}

const InfoDialog = ({ message, close, visible }: Props) => {
  const closeArg: InfoState = {
    message: message,
    close: close,
    visible: false,
  }
  return (
    <FanmeDialog
      text={message}
      visible={visible}
      onClose={() => close(closeArg)}
      onAction={() => close(closeArg)}
      closeOn
      checkIconOn
    />
  )
}

export default InfoDialog
export type { InfoState }
