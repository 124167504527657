import React from 'react'
import { StyledLoadingContent, StyledLoadingSvg, StyledLoadingLabel } from './index.style'
const LoadingIcon = () => {
  return (
    <StyledLoadingContent>
      <StyledLoadingSvg />
      <StyledLoadingLabel>LOADING...</StyledLoadingLabel>
    </StyledLoadingContent>
  )
}

export default LoadingIcon
