import React, { Dispatch, SetStateAction, useRef, useState } from 'react'
import {
  CircleStencil,
  FixedCropper,
  FixedCropperRef,
  ImageRestriction,
  Priority,
  RectangleStencil,
} from 'react-advanced-cropper'
import 'react-advanced-cropper/dist/style.css'
import { Container } from '@nextui-org/react'

import { AxiosResponse } from 'axios'
import FanmeLoading from '@/components/atoms/FanmeLoading'
import MuiButton from '@/components/atoms/MuiButton'
import { Gradation } from '@/constants/styles/color'
import { useToast } from '@/contexts/ToastContext'
import { putForm } from '@/pages/api/base'
import styles from './Trimming.module.css'

type TrimmingCustomUploadEndpoint = {
  url?: string
  formDataBuilder: (blob: Blob, formData: FormData) => FormData
  callback: (response: AxiosResponse) => void
}

interface Props {
  img: string
  aspectRatio: number
  type: string
  cropWidth: number
  framed?: boolean
  moveFree?: boolean
  selectedFile?: HTMLCanvasElement
  creatorName?: string
  accountIdentity?: string
  slowUpdate?: boolean
  uploadLabel?: string
  setTrimModal?: Dispatch<SetStateAction<boolean>>
  setSelectedFile?: Dispatch<SetStateAction<HTMLCanvasElement | undefined>>
  setAccountIdentity?: Dispatch<SetStateAction<string>>
  setCreatorName?: Dispatch<SetStateAction<string>>
  customUploadEndpoint?: TrimmingCustomUploadEndpoint
  cancel: () => void
}

const TrimmingContainer = ({
  img,
  aspectRatio,
  type,
  cropWidth,
  framed,
  moveFree,
  selectedFile,
  customUploadEndpoint,
  setTrimModal,
  setSelectedFile,
  creatorName,
  accountIdentity,
  slowUpdate,
  uploadLabel,
  cancel,
}: Props) => {
  const [image] = useState(img)

  const cropperRef = useRef<FixedCropperRef>(null)

  const [isLoading, setIsLoading] = useState(false)
  const { toast } = useToast()

  const retrieveBlob = async (canvas: HTMLCanvasElement): Promise<Blob | null> => {
    return new Promise((resolve, _) => {
      canvas.toBlob(blob => {
        resolve(blob)
      }, 'image/png')
    })
  }

  const uploadImage = async () => {
    const custom = customUploadEndpoint
    let url = '/creators/self'
    let form = new FormData()

    if (custom && custom.url) {
      url = custom.url
    }

    setIsLoading(true)
    const canvas = cropperRef.current?.getCanvas()
    if (canvas) {
      if (!slowUpdate || custom) {
        const blob = await retrieveBlob(canvas)
        if (!blob) {
          setIsLoading(false)
          return
        }

        if (custom) {
          form = custom.formDataBuilder(blob, form)
        } else {
          form.append('name', creatorName!)
          form.append('account_identity', accountIdentity!)
          form.append('icon', blob)
        }
      } else {
        setIsLoading(false)
        setTrimModal!(false)
        setSelectedFile && setSelectedFile(canvas)
        return
      }
    } else {
      setIsLoading(false)
      return
    }
    try {
      if (custom && !custom.url) {
        setIsLoading(false)
        setTrimModal!(false)
        return
      }

      const response = await putForm(url, form)
      if (response.status === 200) {
        if (custom) {
          custom.callback(response)
        }
        setIsLoading(false)
        setTrimModal!(false)
        setSelectedFile && setSelectedFile(canvas)
        toast.setVisible(true)
        setTimeout(() => {
          toast.setVisible(false)
        }, 1500)
      }
    } catch (e) {
      setIsLoading(false)
      // TODO Error handling
    }
  }

  const calcStencilSize = () => {
    const factor = 0.8
    return {
      width: cropWidth * factor,
      height: cropWidth * (1.0 / (aspectRatio || 1.0)) * factor,
    }
  }

  return (
    <>
      {isLoading && <FanmeLoading />}
      <Container
        css={{
          height: '100%',
          maxWidth: '480px',
          margin: 0,
          padding: 0,
          position: 'fixed',
          zIndex: '10001',
          top: 0,
          left: '50%',
          transform: `translate3d(-50%, 0, 0)`,
        }}
      >
        <FixedCropper
          style={{
            width: '100%',
            height: '100%',
            background: Gradation.PT_30,
          }}
          src={image}
          imageRestriction={moveFree ? ImageRestriction.none : ImageRestriction.stencil}
          stencilComponent={type === 'circle' ? CircleStencil : RectangleStencil}
          stencilProps={{
            handlers: {},
            lines: framed
              ? {
                  west: true,
                  east: true,
                }
              : {},
            aspectRatio: aspectRatio,
            movable: false,
            resizable: false,
            previewClassName: styles.line,
            lineClassNames: framed
              ? {
                  west: styles.frameLeft,
                  east: styles.frameRight,
                }
              : {},
          }}
          priority={Priority.visibleArea}
          ref={cropperRef}
          stencilSize={calcStencilSize()}
        />
        <Container
          css={{
            d: 'flex',
            jc: 'space-between',
            position: 'absolute',
            top: '24px',
            p: '0 24px',
          }}
        >
          <MuiButton onClick={cancel} text="キャンセル" size="sm" width="96px" shadow />
          <MuiButton
            text={uploadLabel || 'アップロード'}
            size="sm"
            width="96px"
            colored
            shadow
            onClick={uploadImage}
          />
        </Container>
      </Container>
    </>
  )
}

export default TrimmingContainer
