import styled from '@emotion/styled'
import { Colors } from '@/constants/styles/color'

const StyledLoadingContent = styled.div`
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
`

const StyledLoadingSvg = styled.div`
  background-image: url('/Loading.svg');
  width: 80px;
  height: 80px;
  margin: 0 auto;

  animation: rotate_anime 2.5s linear infinite;
  @keyframes rotate_anime {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

const StyledLoadingLabel = styled.label`
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.2em;
  color: ${Colors.WHITE};
`

export { StyledLoadingContent, StyledLoadingSvg, StyledLoadingLabel }
