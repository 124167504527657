import { Button, Container, Text } from '@nextui-org/react'
import React from 'react'
import { Colors } from '@/constants/styles/color'
import CheckIcon from '@/assets/img/icons/Check_L.svg'
import ExclamationIcon from '@/assets/img/icons/Exclamation.svg'
import CancelButton from '../CancelButton'

interface Props {
  text: string | React.ReactNode
  visible: boolean
  closeOn?: boolean
  cancelOn?: boolean
  checkIconOn?: boolean
  exclamationIconOn?: boolean
  action?: string
  onAction?: () => void
  onClose: () => void
  actionButtonColor?: string
  actionButtonTextColor?: string
  left?: string
  contents?: string | React.ReactNode
}

const modalBackContainerStyle = (left?: string) => ({
  position: 'fixed',
  mw: '480px',
  h: '100vh',
  top: 0,
  zIndex: 20002,
  padding: 0,
  margin: 0,
  d: 'flex',
  left: left ? left : 'calc((100vw - 480px) / 2)',
  '@media (max-width: 480px)': {
    left: left ? left : 0,
  },
})

const mainContainerStyle = (underButtonOn?: boolean) => ({
  d: 'flex',
  position: 'relative',
  background: Colors.WHITE,
  borderRadius: '16px',
  flexDirection: 'column',
  m: underButtonOn ? 'auto auto 16px auto' : 'auto',
  w: 'calc(100% - 80px)',
  zIndex: 10003,
  padding: 0,
})

interface IconProps {
  checkIconOn?: boolean
}
const Icon = ({ checkIconOn }: IconProps) => {
  return (
    <div
      style={{
        width: '64px',
        height: '64px',
        padding: '4px',
        background: Colors.WHITE,
        borderRadius: '32px',
        margin: '-32px auto 0 auto',
      }}
    >
      <div
        style={{
          display: 'flex',
          width: '56px',
          height: '56px',
          background: checkIconOn ? Colors.FANME_YELLOW : Colors.RED,
          borderRadius: '30px',
        }}
      >
        {checkIconOn ? (
          <CheckIcon
            fill={Colors.BLACK}
            style={{
              margin: 'auto',
            }}
          />
        ) : (
          <ExclamationIcon
            fill={Colors.WHITE}
            style={{
              margin: 'auto',
              width: '32px',
            }}
          />
        )}
      </div>
    </div>
  )
}

interface ActionButtonProps {
  action: string
  actionButtonColor?: string
  actionButtonTextColor?: string
  onAction?: () => void
}
const ActionButton = ({
  actionButtonColor,
  actionButtonTextColor,
  onAction,
  action,
}: ActionButtonProps) => {
  return (
    <Button
      css={{
        bg: actionButtonColor ? actionButtonColor : Colors.WHITE,
        border: actionButtonColor ? 'none' : `1px solid ${Colors.RED}`,
        color: actionButtonTextColor ? actionButtonTextColor : Colors.RED,
        margin: '0 24px 24px 24px',
        fontWeight: '700',
        fs: '14px',
        lh: '14px',
      }}
      onClick={onAction}
      rounded
      auto
    >
      {action}
    </Button>
  )
}
interface CloseButtonProps {
  onClose: () => void
}
const CloseButton = ({ onClose }: CloseButtonProps) => {
  return (
    <Container
      css={{
        d: 'flex',
        position: 'relative',
        background: Colors.WHITE,
        borderRadius: '16px',
        flexDirection: 'column',
        m: '0 auto',
        w: '84px',
        h: '32px',
        zIndex: 10003,
        padding: 0,
      }}
    >
      <Button
        css={{
          bg: Colors.WHITE,
          color: Colors.BLACK,
          margin: '0',
          fontWeight: '700',
          fs: '12px',
          lh: '14px',
        }}
        onClick={onClose}
        rounded
        auto
      >
        閉じる
      </Button>
    </Container>
  )
}

const FanmeDialog = ({
  text,
  visible,
  closeOn,
  cancelOn,
  checkIconOn,
  exclamationIconOn,
  action,
  onClose,
  onAction,
  actionButtonColor,
  actionButtonTextColor,
  left,
  contents,
}: Props) => {
  if (!visible) return <></>
  const iconOn = checkIconOn || exclamationIconOn
  const underButtonOn = closeOn || cancelOn
  return (
    <Container css={modalBackContainerStyle(left)}>
      <div
        style={{
          background: '#00000060',
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: 0,
        }}
        onClick={onClose}
      />
      <Container css={mainContainerStyle(underButtonOn)}>
        {iconOn && <Icon checkIconOn={checkIconOn} />}
        {!contents ? (
          <Text
            size={14}
            color={Colors.BLACK}
            css={{
              m: 'calc(14 / 375 * 100vw) auto',
              fontWeight: '700',
              padding: '16px',
              whiteSpace: 'pre-wrap',
            }}
          >
            {text}
          </Text>
        ) : (
          contents
        )}
        {action && (
          <ActionButton
            action={action}
            actionButtonColor={actionButtonColor}
            actionButtonTextColor={actionButtonTextColor}
            onAction={onAction}
          />
        )}
      </Container>
      {cancelOn && <CancelButton onClose={onClose} />}
      {closeOn && <CloseButton onClose={onClose} />}
    </Container>
  )
}

export default FanmeDialog
