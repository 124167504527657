import React from 'react'
import FanmeDialog from '@/components/molecules/Alert/index'

interface Props {
  message: string | React.ReactNode
  close: (v: ErrorState) => void
  visible: boolean
  contents?: string | React.ReactNode
}

type ErrorState = {
  message: string | React.ReactNode
  close: (v: ErrorState) => void
  visible: boolean
}

const ErrorDialog = ({ message, close, visible, contents }: Props) => {
  const closeArg: ErrorState = {
    message: message,
    close: close,
    visible: false,
  }
  return (
    <FanmeDialog
      text={message}
      visible={visible}
      contents={contents}
      onClose={() => close(closeArg)}
      onAction={() => close(closeArg)}
      closeOn
      exclamationIconOn
    />
  )
}

export default ErrorDialog
export type { ErrorState }
