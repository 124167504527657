import React from 'react'
import { Container } from '@nextui-org/react'
import { Colors } from '@/constants/styles/color'

import LoadingIcon from '@/components/atoms/LoadingIcon'

interface Props {
  height?: string
  top?: string
}

const FanmeLoading = ({ height, top }: Props) => {
  return (
    <Container
      css={{
        width: '100%',
        height: height ? height : '100%',
        maxWidth: '480px',
        zIndex: 20000,
        background: Colors.TRANSPARENT_LIGHT_GRAY,
        position: 'fixed',
        top: top ? top : 0,
        left: 'calc((100% - 480px) / 2)',
        '@media (max-width: 480px)': {
          left: 0,
        },
      }}
      data-testid="fanme-loading"
    >
      <LoadingIcon />
    </Container>
  )
}

export default FanmeLoading
