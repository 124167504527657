import React, { CSSProperties } from 'react'
import { Button } from '@nextui-org/react'
import { Colors } from '@/constants/styles/color'
import { useThemeContext } from '@/contexts/ThemeContext'

interface Props {
  text: string
  outline?: boolean
  disabled?: boolean
  colored?: boolean
  width?: number | string
  shadow?: boolean
  size?: 'sm' | 'lg' | 'md' | 'xl'
  style?: CSSProperties
  onClick?: React.MouseEventHandler<HTMLButtonElement>
}

const MuiButton = ({
  text,
  outline,
  disabled,
  colored,
  width,
  shadow,
  onClick,
  size,
  style,
}: Props) => {
  const { isDark } = useThemeContext()
  return (
    <Button
      data-testid="mui-button"
      css={{
        w: typeof width === 'string' ? width : `${width}px`,
        minWidth: typeof width === 'string' ? width : `${width}px`,
        bg: disabled
          ? Colors.VERY_LIGHT_GRAY
          : colored
          ? Colors.FANME_YELLOW
          : !isDark
          ? Colors.WHITE
          : Colors.BLACK,
        color: disabled
          ? Colors.WHITE
          : colored
          ? Colors.BLACK
          : !isDark
          ? Colors.BLACK
          : Colors.WHITE,
        bw: outline ? '1px' : '0px',
        borderStyle: 'solid',
        borderColor: isDark ? Colors.WHITE : Colors.BLACK,
        bs: !shadow ? 'none' : '0px 0px 15px rgba(0, 0, 0, 0.1)',
      }}
      size={size ? size : 'sm'}
      style={style}
      disabled={disabled}
      onClick={onClick}
      rounded
    >
      {text}
    </Button>
  )
}
export default MuiButton
