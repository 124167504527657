import React from 'react'
import { Button, Container } from '@nextui-org/react'
import { Colors } from '@/constants/styles/color'
import { useThemeContext } from '@/contexts/ThemeContext'

interface Props {
  onClose: () => void
}

const CancelButton = ({ onClose }: Props) => {
  const { isDark } = useThemeContext()
  return (
    <Container
      css={{
        d: 'flex',
        position: 'relative',
        background: Colors.BLACK,
        borderRadius: '24px',
        flexDirection: 'column',
        m: '0 auto',
        w: '248px',
        h: '48px',
        zIndex: 10003,
        padding: 0,
      }}
    >
      <Button
        css={{
          bg: isDark ? Colors.WHITE : Colors.BLACK,
          color: isDark ? Colors.BLACK : Colors.WHITE,
          margin: '0',
          fontWeight: '700',
          fs: '14px',
          lh: '24px',
          w: '100%',
          h: '100%',
        }}
        onClick={onClose}
        rounded
        auto
      >
        キャンセル
      </Button>
    </Container>
  )
}

export default CancelButton
